
import { Vue, Component } from "vue-property-decorator";
import VueperSlide from "@/components/UIComponents/VueperSlide.vue";
import AppHeader from "@/components/UIComponents/AppHeader.vue";
import PriceMonitor from "@/components/UIComponents/PriceMonitor.vue";
import AppFooter from "@/components/UIComponents/AppFooter.vue";
import ContactPerson from "@/components/UIComponents/ContactPerson.vue";
import Analytics from "@/components/UIComponents/Analytics.vue";
import NewsCard from "@/components/UIComponents/NewsCard.vue";
import { MarketStore, TransportStore } from "@/store/modules";
import { Transport } from "@/store/model/transport";
import { nepaliUnicodeNumber } from "@/utils/date";

@Component({
  components: {
    VueperSlide,
    AppHeader,
    PriceMonitor,
    AppFooter,
    ContactPerson,
    Analytics,
    NewsCard,
  },
  //   metaInfo(): MetaInfo {
  //     return {
  //       titleTemplate: 'Marketplace Home',
  //       meta: [
  //         { charset: "utf-8" },
  //         { name: "viewport", content: "width=device-width, initial-scale=1" }
  //       ]
  //     };
  //   }
})
export default class Homescreen extends Vue {
  searchText = "";
  currentPage = 0;
  totalPages = 0;
  tableLoading = false;

  async created() {
    this.tableLoading = true;
    this.fetchProductPriceList();

    await this.fetchPaginatedTransportList();

    this.tableLoading = false;
  }

  getTodayDate() {
    const today = new Date();
    const date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    return date;
  }

  async fetchPaginatedTransportList() {
    const filters: any = {};
    filters["latest"] = "true";

    if (this.searchText) {
      console.log("searchtext");
      await TransportStore.getPaginatedTransportList({
        page: 1,
        searchString: this.searchText,
        filters: filters,
      });
      this.currentPage = 1;
    } else {
      await TransportStore.getPaginatedTransportList({
        page: this.currentPage,
        filters: filters,
      });
    }

    this.paginatedTransportList;
    this.currentPage = this.paginatedTransportList.page;
  }
  async fetchProductPriceList() {
    let market_id = MarketStore.MARKETPLACE_ID;

    // handle date

    let filters: any = {};
    filters["market_id"] = market_id;

    await MarketStore.getProductPriceList({
      filters: filters,
    });
  }

  get ProductPriceList() {
    return MarketStore.ProductPriceList;
  }

  get dataColumn() {
    return [
      {
        name: "product",
        align: "left",
        label: `${this.$i18n.t("commodity")}`,
        field: (data: Transport) =>
          this.$i18n.locale == "en" ? data.product_name : data.product_name_ne,
        sortable: true,
      },

      {
        name: "country_name",
        label: `${this.$i18n.t("country")}`,
        align: "left",
        field: "country_name",
        sortable: true,
        sort: (a: any, b: any) => parseInt(a, 10) - parseInt(b, 10),
      },

      {
        name: "add_address",
        label: `${this.$i18n.t("additionalAddress")}`,
        align: "left",
        field: "add_address",
        sortable: true,
        sort: (a: any, b: any) => parseInt(a, 10) - parseInt(b, 10),
      },

      {
        name: "nepali_date",
        align: "left",
        label: `${this.$i18n.t("date")}`,
        field: (data: Transport) => nepaliUnicodeNumber(data.nepali_date),
      },

      {
        name: "quantity",
        align: "left",
        label: `${this.$i18n.t("quantity")} (${this.$i18n.t("metricTonne")})`,
        field: (data: Transport) => nepaliUnicodeNumber(data.quantity),
        sortable: true,
      },
    ];
  }

  get paginatedTransportList() {
    console.log(TransportStore.PaginatedTransportList);
    return TransportStore.PaginatedTransportList;
  }

  get latestTransportUpdateDate() {
    const transportList = this.paginatedTransportList.results.filter(
      (x) => x.nepali_date != ""
    );
    if (transportList.length > 0) {
      return nepaliUnicodeNumber(transportList[0].nepali_date);
    }
    return "";
  }
  get productPriceList() {
    return MarketStore.ProductPriceList;
  }
  get latestPriceDate() {
    return nepaliUnicodeNumber(this.productPriceList.date_nepali);
  }
}
