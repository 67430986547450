
import { Component, Vue } from "vue-property-decorator";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { VueperSlides, VueperSlide } from "vueperslides";

import "vueperslides/dist/vueperslides.css";

@Component({
  components: {
    VueperSlides,
    VueperSlide,
  },
})
export default class VueperSlider extends Vue {
  slides: Array<any> = [];

  created() {
    if (this.$q.screen.lt.md) {
      this.slides = [
        {
          title: `
            <div style="margin:10px;background:rgba(0, 0, 0, 0.5)">
              <h3 style="color:white;padding:20px;">
                देश भित्र उत्पादित तरकारी तथा फलफूलको बजारीकरणमा यस वजार केन्द्रले ठूलो सहयोग
              </h3>
            </div>
          `,
          image: require("@/assets/img/slide-1.jpg"),
        },

        {
          title: `
            <div style="margin:10px;background:rgba(0, 0, 0, 0.5)">
              <h3 style="color:white;padding:20px;">
                कृषि बजार व्यवस्थापन समिति बुटवल सार्बजनिक निजी साझेदारी अवधारणा अनुरुप निर्माण भएकोले नेपालकै एक
                नमुना वजार केन्द्र 
              </h3>
            </div>
          `,
          image: require("@/assets/img/slide-2.jpg"),
        },
      ];
    }

    if (this.$q.screen.md || this.$q.screen.gt.md) {
      this.slides = [
        {
          title: `
            <div style="margin:200px;background:rgba(0, 0, 0, 0.5)">
              <h2 style="color:white;padding:30px;">
                देश भित्र उत्पादित तरकारी तथा फलफूलको बजारीकरणमा यस वजार केन्द्रले ठूलो सहयोग
              </h2>
            </div>
          `,
          image: require("@/assets/img/slide-1.jpg"),
        },

        {
          title: `
            <div style="margin:200px;background:rgba(0, 0, 0, 0.5)">
              <h2 style="color:white;padding:30px;">
                कृषि बजार व्यवस्थापन समिति बुटवल सार्बजनिक निजी साझेदारी अवधारणा अनुरुप निर्माण भएकोले नेपालकै एक
                नमुना वजार केन्द्र 
              </h2>
            </div>
          `,
          image: require("@/assets/img/slide-2.jpg"),
        },
      ];
    }
  }
}
